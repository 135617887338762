import request from '@/utils/requestv2';
import env from '../../env';

const { NDPURL } = env;

export const getVinInfoForVedio = (data) =>
  request.post('/vehicle-center/vehicle/getVinInfoForVedio', data); // 查询灯光

export const lampControl = (data) => request.post('/vehicle-center/vehicle/lampControl', data); // 修改灯光

export const batchCancelDelivery = (data) =>
  request.post('/order-center/expressOrder/batchCancelDelivery', data); // 批量取消配送

export const batchSecondDelivery = (data) =>
  request.post('/order-center/expressOrder/batchSecondDelivery', data); // 批量二次配送

export const batchOfflineDelivery = (data) =>
  request.post('/order-center/expressOrder/batchOfflineDelivery', data); // 批量线下配送

export const getParkMoveCarPOI = (data) =>
  request.post('/nem-center/poi/getParkMoveCarPOI', data, {
    customHandle: true,
  }); // 获取【支持场内自动挪车】的网点下的挪车点

export const getPoiListByType = (data) => request.post('/nem-center/poi/getPoiListByType', data); // 根据类型获取支持场内挪车下挪车点集合

export const infieldMoveMissionDispatch = (data) =>
  request.post('/vehicle-center/vehicleDispatch/infieldMoveMissionDispatch', data, {
    customHandle: true,
  }); // 根据类型获取支持场内挪车下挪车点集合

export const selectPictureByPoiId = (data) =>
  request.post('/nem-center/poi/selectPictureByPoiId', data); // 查询poi图片
export const batchInsertPicture = (data) =>
  request.post('/nem-center/poi/batchInsertPicture', data); // 批量插入poi图片信息列表
export const deletePicture = (data) => request.post('/nem-center/poi/deletePicture', data); // 删除poi图片
export const uploadPicture = (data) => request.post('/nem-center/poi/uploadPicture', data); // 增加poi图片

export const deletePoi = (data) => request.post('/nem-center/poi/deletePoi', data); // 删除poi

export const searchNearestStationList = (data) =>
  request.post('/nem-center/poi/searchNearestStationList', data); // 搜索POI

export const createPoi = (data) => request.post('/nem-center/poi/createPoi', data); // 创建POI

export const createAppPoi = (data) => request.post('/nem-center/app/poi/createPoi', data); // 创建 App POI

export const getListMission = (data) =>
  request.post('/vehicle-center/callVehicleMission/listMission', data); // 叫车记录
export const getCallVehicleStation = (data) =>
  request.get('/vehicle-center/station/getCallVehicleStation', data); // 叫车-获取装货点卸货点

export const callVehicle = (data) =>
  request.post('/vehicle-center/callVehicleMission/callVehicle', data); // 叫车

export const getStationMissionList = (data) =>
  request.post('/vehicle-center/stationMission/list', data, {
    customHandle: true,
  }); // 站点任务列表
export const getrefreshNum = (data) =>
  request.get('/vehicle-center/stationMission/refreshNum', data, {
    customHandle: true,
  }); // 最多刷新数量

export const coordinateTransform = (data) =>
  request.post(`${NDPURL}aiview/out/coord/info`, data, {
    withoutToken: true,
    customHandle: true,
  });

// 获取停车场信息
export const getParkingPoi = (data) =>
  request.get('/vehicle-center/parkingSpaceInfo/getParkingPoi', data);

// 泊车车位状态查询
export const queryParkingStatus = (data) =>
  request.post('/vehicle-center/parkingSpaceInfo/dispatch/queryParkingStatus', data);

// 泊车车位状态查询
export const queryRelatedParkingTasks = (data) =>
  request.post('/vehicle-center/parkingSpaceInfo/dispatch/queryRelatedParkingTasks', data);

// 车位锁定释放
export const releaseAndLockParking = (data) =>
  request.post('/vehicle-center/parkingSpaceInfo/dispatch/releaseAndLockParking', data, {
    customHandle: true,
  });

// 获取用户权限下车辆
export const getRTKVehicles = () => request.post('/nem-center/app/vehicle/getRTKVehicles', {});

// 更新高精坐标
export const updatePoiLatAndLng = (data) =>
  request.post('/nem-center/app/poi/updatePoiLatAndLng', data);

export const getAllSpecialRiskPoi = (data) =>
  request.get('/nem-center/app/poi/getAllSpecialRiskPoi', data); // 获得特殊标注风险
export const getRiskPoiInfo = (id) =>
  request.get(`/nem-center/special/label/getRiskPoiInfo?id=${id}`);
// 创建或更新风险点
export const saveOrUpdateRiskPoi = (data) =>
  request.post('/nem-center/app/poi/saveOrUpdateRiskPoi', data);

// 更新POI
export const updateAppPoi = (data) => request.post('/nem-center/app/poi/updatePoi', data);

// 获取权益列表
export const fsdList = (data) =>
  request.get('/vehicle-center/vehicleLicense/licenseFsdList', { params: data });
